import { ReactNode } from 'react'
import clsx from 'clsx'
import { ResponsiveProp } from '../../../interface'
import { resolveResponsiveProp } from '../../../utils/resolve-responsive-prop'
import GridItem from './grid-item'
import { GridColumns, GridGap } from './interface'

export interface GridProps {
  children: ReactNode
  className?: string
  columns?: ResponsiveProp<GridColumns>
  gap?: ResponsiveProp<GridGap>
}

const Grid = ({ children, className, columns = 4, gap = 2 }: GridProps) => {
  return (
    <div
      className={clsx(
        'container grid min-w-full',
        resolveResponsiveProp('grid-cols', columns),
        resolveResponsiveProp('gap', gap),
        className,
      )}
    >
      {children}
    </div>
  )
}

Grid.Item = GridItem

export default Grid
