import { FC } from 'react'
import { File as FileIcon, Trash } from 'phosphor-react-sc'
import { roundNumber } from '@ignition/library/src/utils/number'

const styles = {
  container: 'rounded-lg relative bg-white p-4 border-2 border-gray-300',
  fileIcon: 'h-10 w-10 text-gray-400 mx-auto',
  textContainer: 'mt-2 text-center text-sm',
  name: 'font-medium truncate',
  description: 'mt-2 text-gray-500',
  deleteIcon: 'cursor-pointer absolute top-2 right-2 h-6 w-6 text-gray-300 hover:text-gray-500',
}

interface FileProps {
  name: string
  size: number
  onDelete: () => void
}

const File: FC<FileProps> = ({ name = '', size, onDelete }) => {
  return (
    <div className={styles.container}>
      <FileIcon className={styles.fileIcon} weight="light" />
      <div className={styles.textContainer}>
        <h3 className={styles.name}>{name}</h3>
        <p className={styles.description}>{`${getFileSizeInMB(size)}MB`}</p>
      </div>
      <Trash className={styles.deleteIcon} onClick={() => onDelete()} />
    </div>
  )
}

const getFileSizeInMB = (fileSize: number) => {
  if (!fileSize) {
    return ''
  }

  return roundNumber(fileSize / 1024 / 1024)
}

export default File
