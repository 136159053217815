import { FC } from 'react'
import Grid from '@ignition/library/components/atoms/grid'
import File from './file'

interface FilesGridProps {
  files?: File[]
  onDeleteFile: (fileName: string) => void
  className?: string
}

const FilesGrid: FC<FilesGridProps> = ({ files, onDeleteFile, className }) => {
  if (!files || files.length === 0) {
    return null
  }
  return (
    <Grid columns={{ base: 2, sm: 3, md: 4 }} className={className}>
      {files.map((file) => {
        return (
          <Grid.Item key={file.name}>
            <File name={file.name} size={file.size} onDelete={() => onDeleteFile(file.name)} />
          </Grid.Item>
        )
      })}
    </Grid>
  )
}

export default FilesGrid
