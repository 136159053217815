export const getNumbersInRange = (start: number, end: number): number[] => {
  if (start > end) {
    return []
  }

  const length = end - start + 1
  return Array.from({ length }, (_, i) => start + i)
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const isNumber = (value: any): value is number => {
  return typeof value === 'number'
}

export const roundNumber = (value: number, precision = 2) => {
  const multiplier = Math.pow(10, precision)
  return Math.round(value * multiplier) / multiplier
}
