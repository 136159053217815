import { ResponsiveProp, ResponsivePropMap, Screen } from '../interface'
import { isNumber } from './number'
import { isString } from './string'

// return '{name}-{value}' or '{screen}:{name}-{value}'
export const resolveResponsiveProp = (propName: string, prop?: ResponsiveProp): string => {
  if (!prop) {
    return ''
  }

  const objectProp: ResponsivePropMap = isNumber(prop) || isString(prop) ? { base: prop } : prop
  return Object.keys(objectProp)
    .map((key) => {
      const screen = key === 'base' ? '' : `${key}:`
      const value = objectProp[key as Screen]
      return `${screen}${propName}-${value}`
    })
    .join(' ')
}
