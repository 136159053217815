'use client'

import { FC, ReactNode, Ref } from 'react'
import { MouseEvent } from 'react'
import clsx from 'clsx'
import { Switch } from '@headlessui/react'

const styledUndefinedUI = (checked: boolean, allowUndefined?: boolean): string => {
  return clsx(
    allowUndefined ? { 'inline-flex': typeof checked !== 'undefined' } : 'inline-flex',
    checked === false && allowUndefined ? '!bg-red-500 focus:ring-red-500' : 'focus:ring-primary',
  )
}

const styles = {
  switchGroup: (className?: string, reversed?: boolean) =>
    clsx('text-left flex items-center justify-start', !!reversed && 'flex-row-reverse', className),
  switch: (checked: boolean, allowUndefined?: boolean) =>
    clsx(
      styledUndefinedUI(checked, allowUndefined),
      checked ? 'bg-primary' : 'bg-gray-200',
      'relative h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2',
    ),
  span: (checked: boolean) =>
    clsx(
      checked ? 'translate-x-5' : 'translate-x-0',
      'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
    ),
  label: 'text-sm font-medium text-primary whitespace-nowrap',
  description: 'text-sm text-gray-500',
}

interface ToggleProps {
  checked: boolean
  className?: string
  description?: ReactNode
  label?: ReactNode
  allowUndefined?: boolean
  onChange: (checked: boolean) => void
  onClick?: (e: MouseEvent) => void
  ref?: Ref<HTMLDivElement>
  reversed?: boolean
}

const Toggle: FC<ToggleProps> = ({
  checked,
  className,
  description,
  label,
  onChange,
  allowUndefined,
  ref,
  onClick,
  reversed = false,
}) => {
  return (
    <Switch.Group as="div" className={styles.switchGroup(className, reversed)}>
      <div className={clsx(reversed ? 'ml-4' : 'mr-4', 'flex')} ref={ref} onClick={onClick}>
        <Switch onChange={onChange} checked={checked} className={styles.switch(checked, allowUndefined)}>
          <span aria-hidden="true" className={styles.span(checked)} />
        </Switch>
      </div>
      {(label || description) && (
        <div>
          {label && (
            <Switch.Label as="p" className={styles.label} passive>
              {label}
            </Switch.Label>
          )}
          {description && <Switch.Description className={styles.description}>{description}</Switch.Description>}
        </div>
      )}
    </Switch.Group>
  )
}

export default Toggle
