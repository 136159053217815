import { ReactNode } from 'react'
import clsx from 'clsx'
import { ResponsiveProp } from '../../../interface'
import { resolveResponsiveProp } from '../../../utils/resolve-responsive-prop'
import { GridColumns } from './interface'

export interface GridItemProps {
  children: ReactNode
  className?: string
  colspan?: ResponsiveProp<GridColumns>
  colStart?: ResponsiveProp<GridColumns>
}

const GridItem = ({ colspan = 1, colStart, children, className }: GridItemProps) => {
  return (
    <div
      className={clsx(
        resolveResponsiveProp('col-span', colspan),
        resolveResponsiveProp('col-start', colStart),
        className,
      )}
    >
      {children}
    </div>
  )
}

export default GridItem
