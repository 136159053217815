import { DispatchWithoutAction, useReducer } from 'react'

const useToggle = (defaultValue = false): [boolean, DispatchWithoutAction] => {
  const [checked, toggle] = useReducer((state) => {
    return !state
  }, defaultValue)

  return [checked, toggle]
}

export default useToggle
